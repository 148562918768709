@font-face 
  font-family: 'Poppins'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2')
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB

    
html, body
    overflow: hidden

.game-container
    display: block
    margin: 0
    padding: 0

canvas
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.chat
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    height: 50px
    background-color: #fff
    color: #000
    font-size: 1.2em
    padding: 1px
    overflow: hidden
    text-align: center
    z-index: 100

.mainMenu
    background: none
    border: none
    margin: 20px
    &:hover
        cursor: pointer
    img
        width: 100px
        height: 100px
        margin: 0 auto

.show-profile
    display: none
    position: fixed
    top: 0
    right: 0
    width: 20vw
    min-width: 400px
    height: calc(100vh - 50px)
    background-color: #fff
    color: #000
    font-size: 1.2em
    overflow-x: hidden

    .exit
        position: absolute
        top: 10px
        left: 10px
        width: 30px
        height: 30px
        background-color: #fff
        border-radius: 50%
        color: #000
        font-size: 5em
        padding: 1px
        overflow: hidden
        text-align: center
        z-index: 100
        cursor: pointer
        &:hover
            background-color: #000
            color: #fff
    
    .report
        position: absolute
        margin: 0
        padding: 0
        top: 0
        right: 10px
        color: rgb(249, 52, 52)
        font-size: 1em
        z-index: 100
        cursor: pointer
        text-decoration: underline
        &:hover
            color: #ae1515

    .mySwiper
        width: 100%
        height: 50%
    img
        width: 100%
        height: 100%
        object-fit: cover
        object-position: center
    .swiper-pagination
        background-color: #00000095 
        color: #fff
    h1
        font-size: 1.5em
        margin: 0
        padding: 0
        text-align: center
        color: #000
        font-weight: bold
        text-transform: uppercase
        margin-bottom: 1em
        margin-top: 1em
    p
        font-size: 1em
        margin: 0
        padding: 0
        text-align: center
        color: #000
        margin-bottom: 1em
        margin-top: 1em
    .break
        height: 1px
        background-color: #a9a9a9b4
        margin: 0
        padding: 0
        margin-bottom: 2em
        margin-top: 2em
        margin-left: 1em
        margin-right: 1em
    .interaction
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        margin: 0
        div
            width: 33%
            height: 100%
            margin: auto
            display: block
            &:hover
                cursor: pointer
        .add-friend
            color: #0074f8
            &:hover
                color: #0253ae
            p
                color: #0074f8
                &:hover
                    color: #0253ae

        .text
            color: #03a400
            &:hover
                color: #026700
            p
                color: #03a400
                &:hover
                    color: #026700
        .block
            color: #ef2c2c
            &:hover
                color: #a90c0c
            p
                color: #ef2c2c
                &:hover
                    color: #a90c0c

        svg
            display: block
            margin: auto
            width: 30px
            height: 30px
        p
            font-size: .8em
            margin: 0
            padding: 0
            text-align: center
            color: #000
            margin-top: 1em
            margin-left: 1em
            margin-right: 1em


    ul
        list-style: none
        padding: 0
        margin: 0
        text-align: center
        color: #000
        margin-bottom: 1em
        margin-top: 1em
        li
            font-size: 1em
            margin: 5%
            padding: 0
            text-align: left
            color: #000
            margin-bottom: 1em
            margin-top: 1em
            svg
                position: absolute
                margin-top: 0.2em
            span
                margin-left: 1.5em


.navigation 
    position: fixed
    .flexible-modal 
        position: absolute
        z-index: 1
        border: 1px solid #ccc
        background: white
        border-top-left-radius: 15px
        border-top-right-radius: 15px

        
    .flexible-modal-mask 
        position: fixed
        height: 100%
        background: rgba(55, 55, 55, 0.6)
        top:0
        left:0
        right:0
        bottom:0
    
    .flexible-modal-resizer 
        position:absolute
        right:0
        bottom:0
        cursor:se-resize
        margin:5px 5px 0 5px
        border-bottom: solid 2px #333
        border-right: solid 2px #333
    
    .flexible-modal-drag-area
        background: #f38aff5a
        height: 50px
        right:0
        top:0
        border-top-left-radius: 15px
        border-top-right-radius: 15px
        cursor:move
    .my-modal-custom-class
        h3
            text-align: center
            margin: 12px
            padding: 0
        .exit
            position: absolute
            top: 15px
            right: 15px
            width: 20px
            height: 20px
            background-color: #fa5555
            border-radius: 50%
            color: #fff
            font-size: 5em
            padding: 1px
            overflow: hidden
            text-align: center
            z-index: 100
            cursor: pointer
            &:hover
                background-color: #d63f3f
                color: #fff

        .body
            display: flex
            flex-direction: row
            width: 100%
            height: calc(100% - 50px)
        .menu
            display: flex
            flex-direction: column
            width: 20%
            height: 100%
            background-color: #fff
            color: #000
            font-size: 1.2em
            padding: 1px
            overflow: hidden
            text-align: center
            z-index: 100
            button
                width: 100%
                height: 100%
                background-color: #fff
                color: #000
                font-size: 1.2em
                padding: 1px
                overflow: hidden
                text-align: center
                z-index: 100
                outline: 1px solid
                border: none
                &:hover
                    background-color: #1616ff33
                    cursor: pointer
        .content
            width: 80%
            height: 100%
        .map
            width: 100%
            height: 100%
            display: flex
            flex-direction: column
            padding: 1px
            .map-container
                position: relative
                width: 90%
                height: 10%
                outline: 1px solid
                margin-left: 5%
                border-radius: 10px
                margin-top: 10px
                &:hover
                    cursor: pointer
                    background-color: #1616ff33
            p
                position: absolute
                margin: 0
                top: 50%
                margin-left: 20px
                transform: translateY(-50%)
        .friends
            width: 100%
            height: 98%
            padding: 1px
            margin-top: 5px
            overflow: auto
            .friends-container
                position: relative
                width: 90%
                height: 100px
                outline: 1px solid
                margin-left: 5%
            .content
                position: absolute
                display: flex
                margin: 0
                height: 20px
                width: 100%
                top: 50%
                margin-left: 20px
                transform: translateY(-50%)
                svg
                        width: 20px
                        height: 20px
                        margin-right: 10px
                div
                    position: absolute
                    right: 20px
                    svg
                        width: 25px
                        height: 25px
                        margin-right: 30px
                        margin-top: -2px
                        &:hover
                            cursor: pointer
                            color: #1616ff
                    .delete
                        &:hover
                            cursor: pointer
                            color: #ff0000
                
        